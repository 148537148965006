import React, { useState, useEffect, useRef } from 'react';
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

import './Flashcard.css'; // Make sure to create this CSS file


const AddCards = ({ user }) => {
  const [cardFront, setCardFront] = useState('');
  const [cardBack, setCardBack] = useState('');
  const cardFrontRef = useRef(null);
  const cardBackRef = useRef(null);

  const [frontFocus, setFrontFocus] = useState(false);
  const [backFocus, setBackFocus] = useState(false);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  }

  const { width } = useWindowSize();

  useEffect(() => {
    if (cardFrontRef.current) {
      cardFrontRef.current.focus();
    }
  }, []);

  const addCard = async () => {
    if (user && cardFront !== '' && cardBack !== '') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow.setDate(tomorrow.getDate() + 1);

      await addDoc(collection(db, 'Users', user.uid, 'Cards'), {
        front: cardFront,
        back: cardBack,
        lastReview: today,
        nextReview: tomorrow,
        box: 1,
      });

      setCardFront('');
      setCardBack('');

      if (cardFrontRef.current) {
        cardFrontRef.current.focus();
      }
    }
  };

  // Handling Enter key press to add a card
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      addCard();
    }
  };

  if (width < 1024) {
    // Mobile layout
    return (
      <div className="flex flex-col w-screen bg-primary items-center pt-16">
        <div className="flex flex-col items-center">

          <div className="flashcardMobileAddCard">
            <div className={`card'}`}>
              <div className="front">
                <div className={`flex items-center border-b-2 ${frontFocus ? 'animated-border' : 'border-gray'} py-2 animated-border`}>
                  <input
                    ref={cardFrontRef}
                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-0 px-2 leading-tight focus:outline-none"
                    type="text"
                    onFocus={() => setFrontFocus(true)}
                    onBlur={() => setFrontFocus(false)}
                    placeholder="Front"
                    value={cardFront}
                    onChange={(e) => setCardFront(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>

              </div>
            </div>
          </div>

          <div className='w-2 h-6 bg-grey'></div>

          <div className="flashcardMobileAddCard">
            <div className={`card'}`}>
              <div className="front">
                <div className={`flex items-center border-b-2 ${backFocus ? 'animated-border' : 'border-gray'} py-2 animated-border`}>
                  <input
                    ref={cardBackRef}
                    // className="input bg-gray-50 rounded border border-gray-400 leading-normal resize-none w-96 h-10 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white"
                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-0 px-2 leading-tight focus:outline-none"
                    type="text"
                    onFocus={() => setBackFocus(true)}
                    onBlur={() => setBackFocus(false)}
                    placeholder="Back"
                    value={cardBack}
                    onChange={(e) => setCardBack(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
              </div>
            </div>
          </div>


        </div>
        <button
          className="font-opensans bg-green hover:scale-105 text-white text-base font-bold py-2 px-4 mt-6 rounded w-[90vw]"
          onClick={addCard}
        >
          Add Card
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center w-screen h-screen bg-primary pt-16">
        <div className="flex flex-row items-center">

          <div className="flashcard">
            <div className={`card'}`}>
              <div className="front">
                <div className={`flex items-center border-b-2 ${frontFocus ? 'animated-border' : 'border-gray'} py-2 animated-border`}>
                  <input
                    ref={cardFrontRef}
                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-0 px-2 leading-tight focus:outline-none"
                    type="text"
                    onFocus={() => setFrontFocus(true)}
                    onBlur={() => setFrontFocus(false)}
                    placeholder="Front"
                    value={cardFront}
                    onChange={(e) => setCardFront(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>

              </div>
            </div>
          </div>

          <div className='w-32 h-2 bg-grey'></div>

          <div className="flashcard">
            <div className={`card'}`}>
              <div className="front">
                <div className={`flex items-center border-b-2 ${backFocus ? 'animated-border' : 'border-gray'} py-2 animated-border`}>
                  <input
                    ref={cardBackRef}
                    // className="input bg-gray-50 rounded border border-gray-400 leading-normal resize-none w-96 h-10 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white"
                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-0 px-2 leading-tight focus:outline-none"
                    type="text"
                    onFocus={() => setBackFocus(true)}
                    onBlur={() => setBackFocus(false)}
                    placeholder="Back"
                    value={cardBack}
                    onChange={(e) => setCardBack(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
              </div>
            </div>
          </div>


        </div>
        <button
          className="bg-green hover:scale-105 text-white font-bold py-2 px-4 mt-16 rounded"
          onClick={addCard}
        >
          Add Card
        </button>
      </div>
    );
  }
};

export default AddCards;
