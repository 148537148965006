import React, { useState, useEffect } from 'react';
import { signInWithGoogle } from './firebase'; // Assuming signInWithGoogle is exported from './firebase'
import './App.css';

import Flashcard from './Flashcard';

const Splash = () => {

  // const [animateFlip, setAnimateFlip] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  }

  const { width } = useWindowSize();

  if (width < 800) {
    // Mobile layout
    return (
      <>
        <div className="absolute w-full flex flex-row">
          <h1 className="font-madimi text-3xl m-4">Leitnr</h1>
          <div className="flex-grow"></div>
          <button className="m-4" onClick={signInWithGoogle}>
            <img src={`${process.env.PUBLIC_URL}/signInWithGoogle.svg`} alt='Sign in with Google' />
          </button>
        </div>
        <div className="contentBox w-screen h-screen bg-primary flex flex-col items-center justify-center">

          <h1 className="font-madimi leading-none text-5xl mb-0 font-medium gradient-text">
            Streamline
          </h1>
          <h1 className="font-madimi leading-tight text-5xl mb-8 font-medium gradient-text">
            your memory
          </h1>

          <div className="flex flex-col items-center">
            <Flashcard card={{ front: "What is Leitnr.com?", back: "100% free flashcards based on the Leitner spaced repetition system" }} isFlipped={isFlipped} setIsFlipped={setIsFlipped} isLoading={false} animateFlip={true} />
            <div className="flex flex-row mt-16 transform rotate-45 -mb-8 -ml-4">
              <img className="w-16 h-6 scale-y-[-1]" src={`${process.env.PUBLIC_URL}/arrow.png`} alt='arrow' />
              <div className="font-shantell text-3xl ml-4 -mt-12 transform -rotate-45">tap</div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="absolute w-full flex flex-row">
          <h1 className="font-madimi text-3xl m-4">Leitnr</h1>
          <div className="flex-grow"></div>
          <button className="m-4" onClick={signInWithGoogle}>
            <img src={`${process.env.PUBLIC_URL}/signInWithGoogle.svg`} alt='Sign in with Google' />
          </button>
        </div>
        <div className="contentBox w-screen h-screen bg-primary flex flex-col items-center justify-center">

          <h1 className="font-madimi leading-relaxed text-7xl mb-8 font-medium gradient-text">
            Streamline your memory
          </h1>

          <div className="relative flex flex-row">
            <Flashcard card={{ front: "What is Leitnr.com?", back: "100% free flashcards based on the Leitner spaced repetition system" }} isFlipped={isFlipped} setIsFlipped={setIsFlipped} isLoading={false} animateFlip={true} />
            <div className="absolute translate-x-96 translate-y-20 flex flex-row">
              <img className="w-40 h-12" src={`${process.env.PUBLIC_URL}/arrow.png`} alt='arrow' />
              <div className="font-shantell text-3xl ml-2 mt-4">click</div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Splash;
