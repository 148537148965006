// src/firebase/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDw66kQ4y7E_GkcVTdLTtXBtTSL_cw7Jv4",
  authDomain: "leitnr.com",
  projectId: "leitnr-e767d",
  storageBucket: "leitnr-e767d.appspot.com",
  messagingSenderId: "926735143896",
  appId: "1:926735143896:web:d210c00bbe571ce21f58ad",
  measurementId: "G-ZCMWL9WRDL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const db = getFirestore(app);
const auth = getAuth(app);

// Optional: Initialize Firebase Analytics
// const analytics = getAnalytics(app);

// Helper function to get current user
const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider).then((result) => {
    // Handle sign-in result
  }).catch((error) => {
    // Handle errors
    console.error("Error signing in with Google: ", error);
  });
};

export { db, auth, getCurrentUser };
