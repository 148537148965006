import React, { useState, useEffect } from 'react';
import './Flashcard.css'; // Make sure to create this CSS file

function Flashcard({ card, isFlipped, setIsFlipped, isLoading, animateFlip }) {
  // const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  }

  const { width } = useWindowSize();

  return (
    <div className={`${width < 800 ? 'flashcardMobile' : 'flashcard'}`} onClick={handleClick}>
      <div className={`card ${isFlipped ? 'flipped' : ''} ${animateFlip ? 'cardAnimate' : 'cardNoAnimate'}`}>
        {!isLoading ? (
          <>
            <div className="front">{card.front}</div>
            <div className="back">{card.back}</div>
          </>
        ) : (
          <>
            <div className="front"></div>
            <div className="back"></div>
          </>
        )}
      </div>
    </div>
  );
}

export default Flashcard;
