import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { debounce } from 'lodash';

const MyCards = ({ user }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [cards, setCards] = useState([]);
  const [cardEdits, setCardEdits] = useState({});
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    const debouncedSearch = debounce((searchTerm) => {
      if (user) searchCards(searchTerm);
    }, 500);
    if (searchTerm.trim().length > 0) {
      debouncedSearch(searchTerm);
    } else {
      setIsLoading(false)
      setCards([]);
      setCardEdits({});
    }
    return () => debouncedSearch.cancel();
  }, [searchTerm, user]);

  const searchCards = async (searchTerm) => {
    if (!user || searchTerm.length === 0) return;
    const cardsRef = collection(db, 'Users', user.uid, 'Cards');
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const capitalizedSearchTerm = searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1).toLowerCase();
    const queries = [
      query(cardsRef, where("front", ">=", searchTerm), where("front", "<=", searchTerm + '\uf8ff')),
      query(cardsRef, where("back", ">=", searchTerm), where("back", "<=", searchTerm + '\uf8ff')),
      query(cardsRef, where("front", ">=", lowerCaseSearchTerm), where("front", "<=", lowerCaseSearchTerm + '\uf8ff')),
      query(cardsRef, where("back", ">=", lowerCaseSearchTerm), where("back", "<=", lowerCaseSearchTerm + '\uf8ff')),
      query(cardsRef, where("front", ">=", capitalizedSearchTerm), where("front", "<=", capitalizedSearchTerm + '\uf8ff')),
      query(cardsRef, where("back", ">=", capitalizedSearchTerm), where("back", "<=", capitalizedSearchTerm + '\uf8ff')),
    ];
    const snapshots = await Promise.all(queries.map(q => getDocs(q)));
    const combinedDocs = snapshots.flatMap(snapshot => snapshot.docs);
    const uniqueDocs = combinedDocs.filter((doc, index, self) => index === self.findIndex((d) => d.id === doc.id));
    if (uniqueDocs.length > 0) {
      const fetchedCards = uniqueDocs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCards(fetchedCards);
      setCardEdits(fetchedCards.reduce((acc, card) => ({ ...acc, [card.id]: card }), {}));
    } else {
      setCards([]);
      setCardEdits({});
    }
    setIsLoading(false)
  };


  const hasCardChanged = (cardId) => {
    const originalCard = cards.find(card => card.id === cardId);
    const editedCard = cardEdits[cardId];
    return !(originalCard.front === editedCard.front && originalCard.back === editedCard.back);
  };

  const deleteCard = async (cardId) => {
    await deleteDoc(doc(db, 'Users', user.uid, 'Cards', cardId));
    searchCards(searchTerm);
  };

  const handleInputChange = (id, field, value) => {
    setCardEdits(prev => ({
      ...prev,
      [id]: { ...prev[id], [field]: value },
    }));
  };

  const updateCard = async (cardId) => {
    const cardRef = doc(db, 'Users', user.uid, 'Cards', cardId);
    await updateDoc(cardRef, cardEdits[cardId]);
    searchCards(searchTerm);
  };

  return (
    <div className={`flex flex-col ${width < 800 ? 'bg-primary pt-16' : 'bg-primary pt-24'} items-center`}>
      <input
        className={`search-input ${width < 800 ? 'w-11/12' : 'w-96'} bg-gray-50 rounded border border-gray-400 leading-normal resize-none h-10 py-2 px-3 mb-2 font-medium placeholder-gray-700 focus:outline-none focus:bg-white`}
        placeholder="Search cards..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <>
          {searchTerm.trim().length > 0 && (
            <div className='mb-2 '>{cards.length} results</div>
          )}
        </>
      )}

      {cards.map((card) => (
        <div key={card.id} className={`${width < 800 ? 'flex flex-col align-center justify-center w-full my-4' : 'flex flex-row align-center justify-center my-2'}`}>
          <input
            className={`input bg-gray-50 rounded border border-gray-400 leading-normal resize-none ${width < 800 ? 'w-11/12' : 'w-96'} h-10 mx-3 ${width < 800 ? '' : 'ml-0'} px-2 py-2 font-medium placeholder-gray-700 focus:outline-none focus:bg-white`}
            type="text"
            value={cardEdits[card.id]?.front || ''}
            onChange={(e) => handleInputChange(card.id, 'front', e.target.value)}
          />
          <input
            className={`input bg-gray-50 rounded border border-gray-400 leading-normal resize-none ${width < 800 ? 'w-11/12' : 'w-96'} h-10 mx-3 ${width < 800 ? 'mt-2' : 'ml-4'} px-2 py-2 font-medium placeholder-gray-700 focus:outline-none focus:bg-white`}
            type="text"
            value={cardEdits[card.id]?.back || ''}
            onChange={(e) => handleInputChange(card.id, 'back', e.target.value)}
          />
          <div className={`${width < 800 ? 'flex flex-row align-center justify-center w-full mt-2' : 'flex flex-row align-center justify-center'}`}>
            <button onClick={() => deleteCard(card.id)} className={`${width < 800 ? '' : 'ml-4'} bg-red hover:scale-105 w-max text-white font-bold py-2 px-4 rounded`}>
              Delete
            </button>
            <button onClick={() => updateCard(card.id)} disabled={!hasCardChanged(card.id)} className="ml-7 bg-green w-max hover:scale-105 disabled:scale-100 disabled:opacity-30 text-white font-bold py-2 px-4 rounded">
              Update
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyCards;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}