import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

import './index.css';
import './App.css';

import Header from './Header';
import Splash from './Splash';
import Review from './Review';
import MyCards from './MyCards';
import AddCards from './AddCards';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen w-full">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="App">
        <Splash />
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Review user={user} />} />
          <Route path="/review" element={<Review user={user} />} />
          <Route path="/add" element={<AddCards user={user} />} />
          <Route path="/mycards" element={<MyCards user={user} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
