// Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from './firebase';

const Header = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { width } = windowSize;

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsDropdownOpen(false); // Close the dropdown
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(true);
  };

  return (
    <div className="absolute w-full flex flex-row items-center">

      <Link to="/"><h1 className="font-madimi text-3xl m-4">Leitnr</h1></Link>

      {(width > 800) && (
        <>
          <Link to="/"><div className="font-madimi font-medium text-l m-4 mr-0 hover:scale-105 text-white bg-black p-2 px-4 rounded-lg">REVIEW</div></Link>
          <Link to="/add"><div className="font-madimi font-medium text-l m-4 mr-0 hover:scale-105 text-white bg-black p-2 px-4 rounded-lg">ADD CARDS</div></Link>
          <Link to="/mycards"><div className="font-madimi font-medium hover:scale-105 text-l m-4 mr-0 text-white bg-black p-2 px-4 rounded-lg">MY CARDS</div></Link>
        </>
      )}

      <div className="flex-grow"></div>

      <div className="relative group m-4">
        <button onClick={toggleDropdown} className="flex items-center pl-4 py-2 text-sm text-gray-700 rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent">

          {/* Chevron Icon */}
          <svg
            className="w-5 h-5 transform group-hover:-rotate-180 transition-transform duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        {/* Dropdown menu */}
        {isDropdownOpen && (
          <div className="absolute hidden group-hover:block z-10 w-32 py-1 mt-0 bg-white border border-gray-200 rounded-md shadow-lg right-0">
            {/* Log Out Link */}

            {(width < 800) && (
              <>
                <Link to="/"
                  onClick={() => setIsDropdownOpen(false)}
                  className="font-opensans block px-4 py-2 text-l text-gray-700 hover:bg-gray-100"
                >
                  Review
                </Link>

                <Link to="/add"
                  onClick={() => setIsDropdownOpen(false)}
                  className="font-opensans block px-4 py-2 text-l text-gray-700 hover:bg-gray-100"
                >
                  Add Cards
                </Link>

                <Link to="/mycards"
                  onClick={() => setIsDropdownOpen(false)}
                  className="font-opensans block px-4 py-2 text-l text-gray-700 hover:bg-gray-100"
                >
                  My Cards
                </Link>
                <div className='bg-grey h-[1px] w-full'></div>
              </>
            )}

            <a
              href="#"
              className="font-opensans block px-4 py-2 text-l text-gray-700 hover:bg-gray-100"
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              Log Out
            </a>



          </div>
        )}
      </div>


    </div>
  );
};

export default Header;
