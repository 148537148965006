import React, { useState, useEffect } from 'react';
import Flashcard from './Flashcard';
import { Link } from 'react-router-dom';
import { db } from './firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';

const Review = ({ user }) => {
  const [animateFlip, setAnimateFlip] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);
  const [cards, setCards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [empty, setEmpty] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { width } = useWindowSize();

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  }

  useEffect(() => {
    if (user) {
      fetchCards(user.uid);
    }
  }, [user]);

  const fetchCards = async (uid) => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    const cardsRef = collection(db, 'Users', uid, 'Cards');
    const q = query(cardsRef, where('nextReview', '<=', today));
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      setEmpty(true);
    } else {
      const fetchedCards = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCards(fetchedCards);
      setIsDisabled(false);
    }
    setIsLoading(false)
    setIsInitialLoading(false)
  };

  const knew = async () => {
    setIsLoading(true);
    setAnimateFlip(false);
    setIsFlipped(false);
    if (currentIndex < cards.length) {
      setIsDisabled(true);
      const card = cards[currentIndex];
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const nextReviewDate = new Date();
      nextReviewDate.setDate(today.getDate() + card.box * 2);
      const cardRef = doc(db, 'Users', user.uid, 'Cards', card.id);
      await updateDoc(cardRef, {
        box: card.box * 2,
        lastReview: today,
        nextReview: nextReviewDate,
      });
      await fetchCards(user.uid);
    }
    setAnimateFlip(true);
  };

  const didntKnow = async () => {
    setIsLoading(true);
    setAnimateFlip(false);
    setIsFlipped(false);
    if (currentIndex < cards.length) {
      setIsDisabled(true);
      const card = cards[currentIndex];
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      const cardRef = doc(db, 'Users', user.uid, 'Cards', card.id);
      await updateDoc(cardRef, {
        box: 1,
        lastReview: today,
        nextReview: tomorrow,
      });
      await fetchCards(user.uid);
    }
    setAnimateFlip(true);
  };

  if (isInitialLoading) {
    return (
      <div></div>
    );
  }

  if (empty) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center font-opensans text-2xl mx-8">
        You have no new flashcards to review today.
        <Link to="/add">
          <div className="font-opensans bg-green hover:scale-105 text-white text-base font-bold py-2 px-4 mt-6 rounded">Add more cards?</div>
        </Link>
      </div>
    );
  }

  if (width < 800) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        {cards.length > 0 && (
          <Flashcard card={cards[currentIndex]} isFlipped={isFlipped} setIsFlipped={setIsFlipped} isLoading={isLoading} animateFlip={animateFlip} />
        )}
        <div className="flex mt-12">
          <button className="w-24 h-24 rounded-full bg-red text-white text-4xl mr-16 disabled:bg-opacity-50" onClick={didntKnow} disabled={isDisabled}>✘</button>
          <button className="w-24 h-24 rounded-full bg-green text-white text-4xl ml-16 disabled:bg-opacity-50" onClick={knew} disabled={isDisabled}>✔</button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="contentBox w-screen h-screen bg-primary flex flex-row items-center justify-center">
        <button className="w-24 h-24 rounded-full bg-red text-white text-4xl mr-16 disabled:bg-opacity-50" onClick={didntKnow} disabled={isDisabled}>✘</button>
        {cards.length > 0 && (
          <Flashcard card={cards[currentIndex]} isFlipped={isFlipped} setIsFlipped={setIsFlipped} isLoading={isLoading} animateFlip={animateFlip} />
        )}
        <button className="w-24 h-24 rounded-full bg-green text-white text-4xl ml-16 disabled:bg-opacity-50" onClick={knew} disabled={isDisabled}>✔</button>
      </div>
    );
  }
};

export default Review;
